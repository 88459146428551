/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const importAll = (r) => r.keys().map(r)
importAll(require.context('images', false, /\.(png|jpe?g|svg)$/));

require('javascript/src/vendors/backbone_rails_sync');
require('javascript/src/vendors/backbone_datalink');
require('javascript/src/vendors/json2');

require('javascript/src/vendors/jquery.formatCurrency-1.4.0.js');
moment = require('moment-timezone-all');
require('moment/locale/it');

MamaCleanAppClass = require('javascript/src/mamaclean_app.coffee');
window.MamaCleanApp = new MamaCleanAppClass();
window.DeliveryAvailabilitiesClass = require('javascript/src/domain/delivery_availabilities');
Home = require('javascript/src/views/home.coffee');
MessagesView = require('javascript/src/views/messages_view');
Cookies = require('js-cookie');

require('javascript/src/2016/plugins.js');
require('scrollyeah');
require('javascript/src/2016/main');
require('jquery-ujs');